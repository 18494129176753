import axios from 'axios';
import { useReducer, useState, useEffect } from 'react';

const propertyInitialState = {
  id: '0',
  categoria: '',
  tipoPropiedad: '',
  metrosCuadrados: 0,
  precio: 0,
  direccion: '',
  habitaciones: 0,
  banos: 0,
  descripcion: '',
  imagenes: []
};
const initialState = {
  properties: [propertyInitialState],
  filtering: false,
  filteredProperties: [],
  selectedProperty: {
    propertyInitialState
  },
  loading: false,
  error: null
};

const propertyStateManager = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case 'FIND_ALL':
      return {
        ...state,
        loading: false,
        properties: action.payload,
        error: null
      };
    case 'CREATE':
      return {
        ...state,
        selectedProperty: action.payload,
        properties: [...state.properties, action.payload]
      };
    case 'UPDATE':
      return {
        ...state,
        selectedProperty: action.payload,
        properties: state.properties.map(property =>
          property.id === action.payload.id ? action.payload : property
        )
      };
    case 'DELETE':
      return {
        ...state,
        properties: state.properties.filter(
          property => property.id !== action.payload
        )
      };
    case 'FIND_BY_ID':
      return {
        ...state,
        selectedProperty: action.payload,
        loading: false,
        error: null
      };
    case 'FILTERING':
      return {
        ...state,
        filtering: true,
        filteredProperties: action.payload,
        loading: false,
        error: null
      };
    case 'UNFILTER':
      return {
        ...state,
        filtering: false,
        filteredProperties: action.payload,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};

export const propertyReducer = () => {
  const [state, dispatch] = useReducer(propertyStateManager, initialState);
  const [newProperty, setNewProperty] = useState({
    id: 0,
    categoria: '',
    tipoPropiedad: '',
    metrosCuadrados: 0,
    precio: 0,
    direccion: '',
    habitaciones: 0,
    banos: 0,
    descripcion: ''
  });
  const [propertyId, setPropertyId] = useState(0);

  const API_URL =
    'https://ms-generic-client-prd-hycqhqdzgahya8cb.canadacentral-01.azurewebsites.net/api/inmuebles';

  // GET all properties
  const fetchProperties = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await axios.get(API_URL);
      dispatch({ type: 'FIND_ALL', payload: response.data });
    } catch (error) {
      dispatch({ type: 'FETCH_ERROR', payload: 'Error al cargar el usuario' });
    }
  };

  // POST new property
  const createProperty = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await axios.post(API_URL, newProperty);
      dispatch({ type: 'CREATE', payload: response.data });
      setNewProperty(() => ({
        id: 0,
        categoria: '',
        tipoPropiedad: '',
        metrosCuadrados: 0,
        precio: 0,
        direccion: '',
        habitaciones: 0,
        banos: 0,
        descripcion: ''
      }));
    } catch (error) {
      dispatch({ type: 'FETCH_ERROR', payload: 'Error al crear el usuario' });
    }
  };

  // PUT property
  const updateProperty = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await axios.put(API_URL, newProperty);
      dispatch({ type: 'UPDATE', payload: response.data });
      setNewProperty({
        id: 0,
        categoria: '',
        tipoPropiedad: '',
        metrosCuadrados: 0,
        precio: 0,
        direccion: '',
        habitaciones: 0,
        banos: 0,
        descripcion: ''
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_ERROR',
        payload: 'Error al actualizar el usuario'
      });
    }
  };

  // DELETE property
  const deleteProperty = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await axios.delete(`${API_URL}/${propertyId}`);
      dispatch({ type: 'DELETE', payload: response.data });
      setPropertyId(0);
    } catch (error) {
      dispatch({
        type: 'FETCH_ERROR',
        payload: 'Error al eliminar el usuario'
      });
    }
  };

  // GET property by id
  const findPropertyById = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await axios.get(`${API_URL}/${propertyId}`, newProperty);
      dispatch({ type: 'FIND_BY_ID', payload: response.data });
      setNewProperty({ nombre: '', apellido: '', email: '' });
    } catch (error) {
      dispatch({ type: 'FETCH_ERROR', payload: 'Error al buscar el usuario' });
    }
  };

  const filterBasic = formData => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });

      let payload = state.properties;

      if (
        formData.tipoPropiedad.length > 0 ||
        formData.descripcion.length > 0
      ) {
        payload = payload.filter(item =>
          formData.tipoPropiedad.length > 0
            ? item.tipoPropiedad === formData.tipoPropiedad
            : true
        );

        payload = payload.filter(item =>
          formData.descripcion.length > 0
            ? item.descripcion.includes(formData.descripcion)
            : true
        );

        dispatch({ type: 'FILTERING', payload: payload });
      } else {
        dispatch({ type: 'UNFILTER', payload: [] });
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_ERROR',
        payload: 'Error al cargar los usuarios'
      });
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  return {
    state,
    setNewProperty,
    setPropertyId,
    fetchProperties,
    createProperty,
    updateProperty,
    deleteProperty,
    findPropertyById,
    filterBasic
  };
};
