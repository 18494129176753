import axios from 'axios';
import { useEffect, useState } from 'react';

const initialState = {
  data: [
    {
      imagenes: ''
    }
  ],
  loading: true,
  error: null
};

export const useFrontpage = url => {
  const API_URL =
    'https://ms-generic-client-prd-hycqhqdzgahya8cb.canadacentral-01.azurewebsites.net/api';
  const [state, setState] = useState(initialState);

  const fetchData = async () => {
    setState(initialState);
    try {
      const response = await axios.get(`${API_URL}/${url}`);
      setState({ data: response.data, loading: false, error: null });
    } catch (err) {
      setState({
        data: [],
        loading: false,
        error: err.message || 'Error inesperado'
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return state;
};
