import React from 'react';
import eventBanner from 'assets/img/generic/13.jpg';
import { Card, Row, Col } from 'react-bootstrap';
// Button
// import Flex from 'components/common/Flex';
// import Calendar from 'components/common/Calendar';
// import { Link } from 'react-router-dom';
// import IconButton from 'components/common/IconButton';
import Intelligence from 'components/dashboards/analytics/Intelligence';
import { intelligence } from 'data/dashboard/analyticshome';
import { useFrontpage } from 'hooks/useFrontpage';

const EventDetailHeader = () => {
  const { data } = useFrontpage('portada');

  // const eventInfo = {
  //   title: `FREE New Year's Eve Midnight Harbor Fireworks`,
  //   eventDate: { day: '31', month: 'Dec' },
  //   author: 'Boston Harbor Now',
  //   regFee: `$49.99 – $89.99`
  // };
  return (
    <Row>
      <Col
        xxl={7}
        style={{
          height: '510px'
        }}
      >
        <Card
          className="p-0 mb-3"
          style={{ height: '495px', overflow: 'hidden' }}
        >
          {!!eventBanner && (
            <img
              className="card-img-top"
              src={data[0].imagenes}
              alt=""
              style={{
                width: '100%',
                height: '495px',
                objectFit: 'cover'
              }}
            />
          )}
          {/* <Card.Body className="overflow-hidden">
            <Row className="flex-center">
              <Col>
                <Flex>
                  <Calendar {...eventInfo.eventDate} />
                  <div className="fs-10 ms-2 flex-1">
                    <h5 className="fs-9 text-capitalize">{eventInfo.title}</h5>
                    <p className="mb-0 text-capitalize">
                      by
                      <Link className=" ms-1 " to="#!">
                        {eventInfo.author}
                      </Link>
                    </p>
                    <span className="fs-9 text-warning fw-semibold">
                      {eventInfo.regFee}
                    </span>
                  </div>
                </Flex>
              </Col>
              <Col md="auto" className="mt-4 mt-md-0">
                <Flex>
                  <IconButton
                    className="me-2"
                    variant="falcon-default"
                    size="sm"
                    icon="heart"
                    iconClassName="text-danger"
                  >
                    235
                  </IconButton>
                  <IconButton
                    className="me-2"
                    variant="falcon-default"
                    size="sm"
                    icon="share-alt"
                  >
                    Share
                  </IconButton>
                  <Button
                    variant="falcon-primary"
                    size="sm"
                    className="px-4 px-sm-5"
                  >
                    Register
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Card.Body> */}
        </Card>
      </Col>
      <Col
        xxl={5}
        style={{
          height: '510px'
        }}
      >
        <Intelligence data={intelligence} style={{ height: '495px' }} />
      </Col>
    </Row>
  );
};

export default EventDetailHeader;
