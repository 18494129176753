import React from 'react';
// import { Col, Row } from 'react-bootstrap';
// import EventDetailAside from './EventDetailAside';
// import EventDetailContent from './EventDetailContent';
import EventDetailHeader from './EventDetailHeader';
import SimilarCourses from 'components/app/e-learning/course/course-details/SimilarCourses';
import CourseFeatures from 'components/app/e-learning/course/course-details/CourseFeatures';
// import { Card } from 'react-bootstrap';
// import Courses from 'components/app/e-learning/course/Courses';
import { courseFeatures } from 'data/elearning/courseDetails';
import Revenue from 'components/dashboards/crm/revenue/Revenue';
import { propertyReducer } from 'reducers/propertyReducer';
// import { useFrontpage } from 'hooks/useFrontpage';

const EventDetail = () => {
  const { state } = propertyReducer();

  console.log(state);

  return (
    <>
      <EventDetailHeader xl={6} sm={12} />
      <SimilarCourses courseData={state.properties} />
      <CourseFeatures data={courseFeatures} />
      <Revenue>
        <SimilarCourses courseData={state.properties} />
      </Revenue>
      {/* <Courses /> */}
    </>
  );
};

export default EventDetail;
