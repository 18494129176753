import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Background from 'components/common/Background';
// import corner6 from 'assets/img/illustrations/corner-6.png';
import {
  Button,
  Card,
  Col,
  Form,
  // InputGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import useCourses from 'hooks/useCourses';
// import paths from 'routes/paths';

const CourseHeader = ({ layout, setLayout, filterBasic }) => {
  // const { handleSearch, handleIsAscClick, handleSortByChange, isAsc } =
  //   useCourses();

  const [formData, setFormData] = useState({
    categoria: '',
    descripcion: '',
    tipoPropiedad: '',
    precio: '',
    moreFilters: '',
    orderBy: ''
  });

  useEffect(() => {
    filterBasic(formData);
    console.log(formData);
  }, [formData]);

  return (
    <Card className="mb-3">
      <Card.Header className="position-relative">
        <h5 className="mb-0 mt-1">Encuentra tu propiedad ideal</h5>
        <Background className="d-none d-md-block bg-card" />
      </Card.Header>
      <Card.Body className="pt-0 pt-md-3">
        <Row className="g-3 align-items-center">
          <Col xs="auto">
            <Form as={Row} className="gx-2 position-relative">
              <Col lg={2} md={6} sm={12}>
                <Form.Select
                  id="categoria"
                  value={formData.categoria}
                  className="pe-5"
                  size="sm"
                  onChange={({ target }) =>
                    setFormData({ ...formData, categoria: target.value })
                  }
                  aria-label="Default select example"
                >
                  <option>En venta</option>
                  <option value="1">En alquiler</option>
                </Form.Select>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Form.Control
                  id="descripcion"
                  value={formData.descripcion}
                  type="search"
                  placeholder="Search..."
                  size="sm"
                  aria-label="Search"
                  className="rounded search-input ps-4"
                  onChange={({ target }) =>
                    setFormData({ ...formData, descripcion: target.value })
                  }
                />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Form.Select
                  id="tipoPropiedad"
                  value={formData.tipoPropiedad}
                  className="pe-5"
                  size="sm"
                  onChange={({ target }) =>
                    setFormData({ ...formData, tipoPropiedad: target.value })
                  }
                  aria-label="Default select example"
                >
                  <option>Tipo</option>
                  <option value="Casa">Casa</option>
                  <option value="Departamento">Departamento</option>
                  <option value="Oficina">Oficina</option>
                  <option value="Terreno">Terreno</option>
                  <option value="Local comercial">Local comercial</option>
                  <option value="Bodega">Bodega</option>
                  <option value="Edificio">Edificio</option>
                  <option value="Casa de campo">Casa de campo</option>
                  <option value="Penthouse">Penthouse</option>
                  <option value="Loft">Loft</option>
                  <option value="Villa">Villa</option>
                  <option value="Dúplex">Dúplex</option>
                  <option value="Chalet">Chalet</option>
                </Form.Select>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Form.Select
                  id="precio"
                  value={formData.precio}
                  className="pe-5"
                  size="sm"
                  onChange={({ target }) =>
                    setFormData({ ...formData, precio: target.value })
                  }
                  aria-label="Default select example"
                >
                  <option>Presupuesto</option>
                </Form.Select>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Form.Select
                  id="moreFilters"
                  value={formData.moreFilters}
                  className="pe-5"
                  size="sm"
                  onChange={({ target }) =>
                    setFormData({ ...formData, moreFilters: target.value })
                  }
                  aria-label="Default select example"
                >
                  <option>Más filtros</option>
                  <option value="1">50 000 hasta 100 000</option>
                  <option value="2">100 000 hasta 150 000</option>
                  <option value="3">150 000 hasta 200 000</option>
                  <option value="4">250 000 hasta 300 000</option>
                </Form.Select>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Form.Select
                  id="orderBy"
                  value={formData.orderBy}
                  className="pe-5"
                  size="sm"
                  onChange={({ target }) =>
                    setFormData({ ...formData, orderBy: target.value })
                  }
                  aria-label="Default select example"
                >
                  <option>Ordenar por</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
            </Form>
          </Col>
          <Col xs="auto" className="col-md">
            <Row className="g-0 justify-content-end">
              <Col xs="auto" className="p-0">
                <Row className="g-2 align-items-center">
                  <Col xs="auto" className="d-none d-lg-block">
                    <small>View:</small>
                  </Col>
                  <Col xs="auto mb-1 mb-md-0">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip style={{ position: 'fixed' }}>
                          {layout === 'grid' ? 'course-grid' : 'course-list'}
                        </Tooltip>
                      }
                    >
                      {/* <Link
                        to={paths.courses('course-grid')}
                        className={`me-3 ${
                          layout === 'grid' ? 'text-700' : 'text-400 hover-700'
                        }`}
                      >
                        <FontAwesomeIcon
                          icon="th"
                          transform="down-3"
                          className="fs-8"
                        />
                      </Link> */}
                      <Button
                        variant="danger"
                        style={{
                          backgroundColor: '#940000'
                        }}
                        onClick={() =>
                          setLayout(
                            layout === 'grid' ? 'course-list' : 'course-grid'
                          )
                        }
                        className={`me-3 
                          ${
                            layout === 'grid'
                              ? 'text-700'
                              : 'text-400 hover-700'
                          }`}
                      >
                        {layout === 'grid' ? (
                          <FontAwesomeIcon
                            icon="list-ul"
                            transform="down-3"
                            className="fs-8 hover-700"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="th"
                            transform="down-3"
                            className="fs-8"
                          />
                        )}
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CourseHeader.propTypes = {
  layout: PropTypes.string,
  setLayout: PropTypes.func,
  filterBasic: PropTypes.func
};

export default CourseHeader;
