import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/illustrations/logo-organizacion.png';

const Logo = ({ at = 'auth', className, ...rest }) => {
  // width = 58
  // textClass
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
      style={{
        height: '6vh'
      }}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-4 mb-4': at === 'auth'
          },
          className
        )}
        style={{
          height: '6vh',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <img className="me-2" src={logo} alt="Logo" style={{ height: `4vh` }} />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  // width: PropTypes.number,
  className: PropTypes.string
  // textClass: PropTypes.string
};

export default Logo;
